<template>
  <div class="dropdown navbar-avatar ms-2 ms-lg-3">
    <template v-if="$contact?.profile_picture">
      <img id="profile-avatar" class="dropdown-toggle icon" type="button" data-bs-toggle="dropdown" aria-expanded="false" :src="$contact.profile_picture.public_path"/>
    </template>

    <template v-else>
      <button class="btn btn-secondary btn-secondary__login dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          {{name}}
      </button>
    </template>

    <ul class="dropdown-menu">
      <li><a class="dropdown-item px-3 py-2" href="/perfil">Perfil</a></li>
      <li><a class="dropdown-item px-3 py-2" href="/profile/logout">Cerrar sesión</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  inject: ['$contact'],
  data: () => ({
    image: null
  }),
  computed: {
    name() {
      return (this.$contact['given_name'] || ' ')[0] + (this.$contact['last_name'] || ' ')[0];
      // use second_last_name too?
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-secondary__login {
  min-width: 54px;
  min-height: 54px;
}
</style>